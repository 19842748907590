<template>
  <div>
    <nav class="main-nav">
      <div class="top-head">
        <ul>
          <li>
            <img src="~@/assets/logo.png" alt="" />
          </li>
          <li>
            <div class="company-name">MurshiSoft</div>
          </li>
        </ul>
      </div>
      <div class="main-menu">
        <router-link to="/"> Home</router-link>
        <router-link to="/process"> Process</router-link>
        <router-link to="/products"> Products</router-link>
        <router-link to="/contactus"> Contact</router-link>
        <router-link v-if="loggedIn" to="/dashboard"> Logout</router-link>
        <router-link v-else to="/login"> Login</router-link>
        <!-- <div class="dropdown">
          <a class="dropbtn">
            Our Products
            <i class="fa fa-caret-down"></i>
          </a>
          <div class="dropdown-content">
            <router-link to="/pos">MurshiSoft Bizness</router-link>
            <router-link to="/pos">Murshisoft POS for Retailers</router-link>
            <router-link to="/pos">Murshisoft POS for Restaurants</router-link>
            <router-link to="/pos">Murshisoft Project Management</router-link>
            <router-link to="/pos">Murshisoft Hospital Management</router-link>
            <router-link to="/pos"
              >Murshisoft POS for Petrol Stations</router-link
            >
          </div>
        </div> -->
      </div>
      <!-- <ul class="right-menu">
        <li>
          <a href="#">
            <i class="fas fa-search"></i>
          </a>
        </li>
        <li>
          <a href="#">
            <i class="fas fa-shopping-cart"></i>
          </a>
        </li>
      </ul> -->
    </nav>
  </div>
</template>

<script>
import { getUserInfo } from "@/data/auth";

import { defineComponent, reactive, ref, onMounted } from "vue";
export default defineComponent({
  name: "Navbar",
  setup() {
    const user = reactive({});
    const loggedIn = ref(false);
    onMounted(() => {
      getUserDetails();
      window.addEventListener("authChanged", (event) => {
        loggedIn.value = event.detail.isLoggedIn;
      });
    });
    function getUserDetails() {
      user.value = getUserInfo();
      loggedIn.value = user.value ? user.value.DisplayName.length > 1 : false;
    }
    return { user, loggedIn };
  },
});
</script>
<style lang="scss" scoped>
a {
  text-decoration: none;
}

ul {
  list-style: none;
}

.main-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 110px;
  padding: 20px 0;
  font-size: 17px;
  background: #fff;
  /* margin: 10px 0 10px 0; */
  border-bottom: #ccc 1px solid;
  .logo {
    width: 110px;
    padding: 20px;
  }
  .main-menu {
    display: flex;
    router-link .dropdown .dropbtn {
      padding: 0 10px;
    }
    a {
      padding-bottom: 2px;
      color: #000;
      font-size: 22px;
      padding: 10px;
      font-family: Arial, Helvetica, sans-serif;
      font-weight: bold;
      flex: 1;
      margin-left: 20px;
      &:hover {
        border-bottom: 2px solid #262626;
        color: rgb(255, 196, 0);
        background: rgba(128, 128, 128, 0.048);
      }
    }
  }
  .top-head {
    // background: #a7adb3;
    // background-image: url("~@/assets/background.jpg");
    color: #2aabe1;
    // #10293f;
    //  #a7adb3;
    .company-name {
      font-size: 40px;
      font-weight: bold;
      padding: 0 20px;
    }
  }
  .top-head ul {
    display: flex;
    align-items: center;
    padding: 2px;
  }
  .top-head img {
    height: 100px;
  }
}
// Dropdown
.dropdown {
  float: left;
  overflow: hidden;
}
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  float: none;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}
.dropdown-content a:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  display: block;
}
</style>
