<template>
  <div class="container">
    <transition-group v-if="currentItem" name="fade" tag="div">
      <div
        class="slider-item"
        v-for="i in [currentIndex]"
        :key="i"
        :style="{
          background:
            'url(' +
            require('@/assets/slider/' + currentItem.image) +
            ')  no-repeat center center/cover',
        }"
      >
        <h1>
          <mark>{{ currentItem.caption }}</mark>
        </h1>
        <p>
          <span>{{ currentItem.details }}</span>
        </p>
        <!-- <img :src="currentItem.image" /> -->
      </div>
    </transition-group>
    <a class="prev" @click="prev" href="#">&#10094;</a>
    <a class="next" @click="next" href="#">&#10095;</a>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "Slider",
  data() {
    return {
      items: [],
      timer: null,
      currentIndex: 0,
    };
  },

  async mounted() {
    try {
      let response = await axios.get("data/slider.json");
      this.items = response.data;
      this.startSlide();
    } catch {
      // console.log(err);
    }
  },

  methods: {
    startSlide: async function() {
      this.timer = setInterval(this.next, 4000);
    },

    next: function() {
      this.currentIndex += 1;
    },
    prev: function() {
      this.currentIndex -= 1;
    },
  },

  computed: {
    currentItem: function() {
      return this.items[Math.abs(this.currentIndex) % this.items.length];
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  height: 600px;
  width: 100vh;
  margin: auto;
  .fade-enter-active,
  .fade-leave-active {
    transition: all 0.9s ease;
    overflow: hidden;
    visibility: visible;
    position: absolute;
    width: 100%;
    opacity: 1;
  }

  .fade-enter,
  .fade-leave-to {
    visibility: hidden;
    width: 100%;
    opacity: 0;
  }

  .slider-item {
    height: 600px;
    width: 100vh;
    // max-width: 1100px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    h1 {
      font-size: 40px;
      color: #2aabe1;
      padding-top: 40px;
      text-align: center;
    }
    p {
      width: 50%;
    }
    span {
      font-size: 20px;
      background: #2aabe1;
      color: #fff;
      text-align: center;
    }
    //   background: url("~@/assets/showcase.jpeg") no-repeat center center/cover;
  }

  .prev,
  .next {
    cursor: pointer;
    position: absolute;
    top: 40%;
    width: auto;
    padding: 16px;
    color: white;
    font-weight: bold;
    font-size: 18px;
    transition: 0.7s ease;
    border-radius: 0 4px 4px 0;
    text-decoration: none;
    user-select: none;
  }

  /* Position the "next button" to the right */
  .next {
    right: 0;
  }

  .prev {
    left: 0;
  }

  /* On hover, add a black background color with a little bit see-through */
  .prev:hover,
  .next:hover {
    background-color: rgba(0, 0, 0, 0.9);
  }
}
</style>
