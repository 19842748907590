import decode from "jwt-decode";
import
{
  ref,
  reactive
} from "vue";
import
{
  myhttp
} from "@/data/myhttp";
import
{
  useRouter
} from "vue-router";
let token = localStorage.getItem("authToken");

export function getAuthToken()
{
  window.addEventListener("authChanged", (event) =>
  {
    token = event.detail.authToken;
  });
  return token ? token : null;
}
export function LogIn(user)
{
  const router = useRouter();
  const data = ref(null);
  const error = ref(null);
  const isLoading = ref(false);
  async function sign()
  {
    try
    {
      isLoading.value = true;
      let response = await myhttp({
        url: "/users/login",
        method: "POST",
        data: user,
      });
      isLoading.value = false;
      data.value = response.data;
      const token = response.data ? response.data.authToken : null;
      console.log(token);
      setAuthToken(token);
      router.push({
        name: "dashboard",
      });

      // alert(`Token received: ${res.data.token}`)
    } catch (err)
    {
      console.log(`Error: ${err}`);
      error.value = err;
    }
  }
  return {
    data,
    error,
    isLoading,
    sign,
  };
}
export function setAuthToken(token)
{
  localStorage.setItem("authToken", token);
  window.dispatchEvent(new CustomEvent('authChanged', {
    detail: {
      authToken: localStorage.getItem("authToken"),
      isLoggedIn: true,
    }
  }));
  // store.commit('setToken', token);
  // let user = getUserInfo();
  // store.commit('setUser', user);
}
export function isLoggedIn()
{
  let authToken = getAuthToken();
  return !!authToken && !isTokenExpired(authToken);
}

export function getUserInfo()
{
  if (isLoggedIn())
  {
    return decodeToken(getAuthToken());
  }
  return null;
}

function getTokenExpirationDate(encodedToken)
{
  let token = decodeToken(encodedToken);
  if (!token.exp)
  {
    return null;
  }

  let date = new Date(0);
  date.setUTCSeconds(token.exp);

  return date;
}

function isTokenExpired(token)
{
  let expirationDate = getTokenExpirationDate(token);
  return expirationDate < new Date();
}

export function logout()
{
  clearAuthToken();
}
export function clearAuthToken()
{
  localStorage.setItem("authToken", null);
  window.dispatchEvent(new CustomEvent('authChanged', {
    detail: {
      authToken: null,
      isLoggedIn: false,
    }
  }));
}

export function decodeToken(token)
{
  // return decode(token);
  try
  {
    return reactive(decode(token));
  } catch {
    return "";
  }
}