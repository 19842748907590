<template>
  <div id="app">
    <Navbar id="myHeader" />
    <!-- <v-if="homePage"Slider /> -->
    <div class="container">
      <router-view />
    </div>
  </div>
  <Footer />
</template>
<script>
// import Slider from "@/components/Slider";
import Footer from "@/components/Footer";
import Navbar from "@/components/Navbar";
export default {
  components: { Navbar, Footer },
  computed: {
    homePage() {
      if (this.$route.path == "/" || this.$route.path == "/home") {
        return true;
      } else {
        return false;
      }
    },
  },
  data() {
    return {
      header: null,
      stickey: 0,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    this.header = document.getElementById("myHeader");

    // Get the offset position of the navbar
    this.sticky = this.header.offsetTop;
  },
  methods: {
    handleScroll() {
      // console.log(e);
      // var scrollPos = e.target.scrollTop
      if (window.pageYOffset > this.sticky) {
        this.header.classList.add("sticky");
      } else {
        this.header.classList.remove("sticky");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  // max-width: 1100px;
  margin: auto;
}

.sticky + .content {
  padding-top: 102px;
}
ul {
  list-style-type: none;
}
</style>
