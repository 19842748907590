<template>
  <footer class="footer">
    <div class="first">
      <h3 style="padding: 5px 0">©2020MurshiSoft LLP</h3>
      <h3 style="padding: 5px 0">GSTIN: 32ABOFM2518G1ZE</h3>
      <h3 style="padding: 5px 0">
        <router-link to="/contactus">Contact Us</router-link>
      </h3>
    </div>
    <div class="first">
      <h3 style="padding: 5px 0">
        <router-link to="/terms">Terms and Conditions</router-link>
      </h3>
      <h3 style="padding: 5px 0">
        <router-link to="/privacy-statement">Privacy Statement</router-link>
      </h3>
      <h3 style="padding: 5px 0">
        <router-link to="/refund-policy">Refund Policy</router-link>
      </h3>
    </div>
    <div>
      third column
    </div>
  </footer>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
ul {
  list-style: none;
}
.footer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  img {
    height: 100px;
    padding-top: 20px;
    padding-left: 20px;
  }
  .first {
    color: white;
    padding-left: 20px;
    padding-top: 20px;
  }
  a {
    color: white;
  }
}
</style>
