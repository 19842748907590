// eslint-disable-next-line no-unused-vars
import NProgress from "nprogress"

import {
  createRouter,
  createWebHistory
} from "vue-router";
import Home from "../views/Home.vue";
const routes = [

  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/:catchAll(.*)",
    name: 'notFound',
    component: () => import("@/views/404.vue")
  },
  {
    path: "/pos",
    name: "pos",
    component: () => import("@/views/Pos.vue")
  },
  {
    path: "/process",
    name: "process",
    component: () => import("@/views/Process.vue")
  },
  {
    path: "/contactus",
    name: "contactus",
    component: () => import("@/views/Contactus.vue")
  },
  {
    path: "/products",
    name: "products",
    component: () => import("@/components/ProductsList.vue")
  },
  {
    path: "/productdetail",
    name: "productdetail",
    component: () => import("@/components/ProductDetail.vue")
  },
  {
    path: "/terms",
    name: "terms",
    component: () => import("@/views/Terms.vue")
  },
  {
    path: "/privacy-statement",
    name: "privacy-statement",
    component: () => import("@/views/Privacy.vue")
  },
  {
    path: "/refund-policy",
    name: "refund-policy",
    component: () => import("@/views/Refund.vue")
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/Login.vue")
  },
  {
    path: "/pay",
    name: "pay",
    component: () => import("@/views/Razorpay.vue")
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: () => import("@/views/Dashboard.vue")
  },
  {
    path: "/stripe",
    name: "stripe",
    component: () => import("@/views/Stripe.vue")
  },
  {
    path: "/2checkout",
    name: "2checkout",
    component: () => import("@/views/2Checkout.vue")
  },
  {
    path: "/paymentResult",
    name: "paymentResult",
    component: () => import("@/views/PaymentResult.vue")
  },

  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import( /* webpackChunkName: "about" */ "../views/About.vue")
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});
router.beforeResolve((to, from, next) => {
  // If this isn't an initial page load.
  if (to.name) {
    // Start the route progress bar.
    NProgress.start()
  }
  next()
})
// eslint-disable-next-line no-unused-vars
router.afterEach((to, from) => {
  // Complete the animation of the route progress bar.
  NProgress.done()
})

export default router;