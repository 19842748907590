<template>
  <div class="who-container">
    <div class="head-container">
      <div class="heading">
        {{ data.heading }}
      </div>
      <div class="subheading">
        {{ data.subheading }}
      </div>
      <div class="paragraph">
        {{ data.paragraph }}
      </div>
    </div>
    <div class="detail-container">
      <div class="inner" v-for="d in data.details" :key="d.title">
        <img :src="require('@/assets/who/' + d.icon)" alt="" />
        <h3>{{ d.title }}</h3>
        <p>{{ d.paragraph }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "Who",
  async mounted() {
    let response = await axios.get("data/who.json");
    this.data = response.data;
  },
  data() {
    return {
      data: {},
    };
  },
};
</script>

<style lang="scss" scoped>
.who-container {
  background: #fff;
  .head-container {
    padding: 20px 0;
    .heading {
      font-size: 70px;
      font-weight: bolder;
      text-align: center;
      padding: 50px 0;
    }
    .subheading {
      font-size: 50px;
      font-weight: bolder;
      padding: 30px 0;
    }
    .paragraph {
      padding: 4px;
      font-size: 40px;
    }
  }
  .detail-container {
    display: grid;
    column-gap: 5px;
    grid-template-columns: 1fr 1fr;
    .inner {
      img {
        margin: auto;
      }
      h3 {
        font-size: 50px;
        margin: auto;
        padding: 50px 0;
      }
      p {
        font-size: 40px;
        padding: 20px 0;
      }
    }
  }
}
</style>
