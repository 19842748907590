import {
  createApp
} from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import 'bootstrap/dist/css/bootstrap.min.css';
import "nprogress/nprogress.css";
var app = createApp(App);
// import VueMeta from "vue-meta"
import {
  config
} from "@/config.js";
// app.config.globalProperties.window
app.config.globalProperties.config = config;
//Axios:
import axios from "axios";
const instance = axios.create({
  baseURL: config.$api_url
});
app.config.globalProperties.$http = instance;
app.config.globalProperties.$base_url = config.$base_url;

app
  .use(store)
  .use(router)
  // .use(VueMeta)
  .mount("#app");