let config;

if (process.env.NODE_ENV === "production")
{
    config = {
        $api_url: "https://murshisoft.com/api",
        $base_url: "https://murshisoft.com"
    };
} else
{
    config = {
        $api_url: "https://murshisoft.com/api",
        $base_url: "https://murshisoft.com"
    };
}

export
{
    config
};