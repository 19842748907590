import NProgress from "nprogress"

import axios from 'axios';
import {
    config
} from "@/config.js";
import {
    getAuthToken
} from "@/data/auth";
const defaultOptions = {
    baseURL: config.$api_url,
    headers: {
        'Content-Type': 'application/json',
    },
};

// Create instance
export const myhttp = axios.create(defaultOptions);

// Set the AUTH token for any request
myhttp.interceptors.request.use(function (config) {
    NProgress.start()
    const token = getAuthToken();
    config.headers.Authorization = token ? `Bearer ${token}` : '';
    return config;
});

myhttp.interceptors.response.use(response => {
    NProgress.done()
    return response
  })
  