import {
  createStore
} from "vuex";

export default createStore({
  state: {
    products: [],
    users: []
  },
  getters: {
    getProducts: state => {
      return state.products;
    },
    getUsers: state => {
      return state.users;
    }
  },
  mutations: {
    setUsers(state, users) {
      state.users = users;
    },
    setProducts(state, products) {
      state.products = products;
    }

  },
  actions: {},
  modules: {}
});