<template>
  <div class="home">
    <Slider />
    <Who style="margin-top:30px;" />
    <!-- <Homecard />
    <Xbox /> -->
  </div>
</template>

<script>
// @ is an alias to /src
// import Showcase from "@/components/Showcase";
// import Homecard from "@/components/Homecard";
// import Xbox from "@/components/Xbox";
import Slider from "@/components/Slider";
import Who from "@/components/Who";
// import Who from "@/components/ًWho.vue";

export default {
  name: "Home",
  components: { Slider, Who },
};
</script>
<style scoped></style>
